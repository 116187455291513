@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&family=Press+Start+2P&display=swap');

html, body {
  margin: 0;
  overflow-x: hidden;
}

/* FONTS AND COLOR PALETTE */
:root {
  --shade1: #000000;
  --shade2: #202020;
  --shade3: #404040;
  --shade4: #606060;
  --shade5: #808080;
  --shade6: #A0A0A0;
  --shade7: #e9e5e5;
  --green: #009F00;
  --orange: #FC8E2E;
  --blue: #192EEA;
  --red: #e2bbc9;
  --game-font: 'Press Start 2P', cursive;
  --code: 'Fira Code', monospace;
}


/* GENERAL TEMPLATES */
h1, h2, h3 {
  font-family: var(--game-font);
}

h4, h5, p, li {
  font-family: var(--code);
}

p, li {
  font-size: clamp(16px, 1.5vw, 20px);
}

a, a:visited {
  color: inherit;
}

ul {
  list-style: none;
}

#landing, #about, #experience, #projects, #contact {
  max-width: 1500px;
  min-height: 100vh;
  margin: 0px auto;
  display: flex;
}

.highlight-green {
  color: var(--green);
  font-weight: 600;
}

.highlight-blue {
  color: var(--blue);
  font-weight: 600;
}

/* Navbar */
#navbar {
  position: fixed;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(250, 247, 247, 0.2), 0 6px 20px 0 rgba(148, 142, 142, 0.19);
  padding: 2px 5px;
  transition: 0.5s;
  background: white;
  opacity: 0.9;
  z-index: 999;
}

#navbar:hover {
  box-shadow: 0 4px 8px 0 rgba(219, 218, 218, 0.2), 0 6px 30px 0 rgba(109, 105, 105, 0.19);
}

#navbar .home-button {
  position: relative;
  cursor: pointer;
  margin: 16px 20px 0;
  width: 26px;
}

#navbar .nav-contents {
  width: fit-content;
  float: right;
  margin-right: 20px;
}

#navbar ul {
  display: inline-flex;
}

#navbar .nav-button {
  position: relative;
  padding: 5px 8px;
  margin: 0 5px;
  cursor: pointer;
  transition: 0.3s;
  top: 0;
  font-size: 15px;
  font-family: var(--code);
  border: 2px dashed transparent;
  text-decoration: none;
}

#navbar .nav-button:hover {
  color: var(--green);
  top: -2px;
  font-weight: 500;
  border: 2px dashed var(--green);
}


/* Landing */
#landing {
  background-image: url("/src/graphics/landing-bg.svg");
  background-repeat: no-repeat, repeat;
  background-size: 1425px;
  background-position-y: bottom;
}

#landing .typewriter {
  margin: auto;
}

#landing .Typewriter {
  font-family: var(--game-font);
  color: var(--shade3);
  font-size: clamp(20px, 2.2vw, 50px);
  font-weight: 700;
}


/* About */
#about {
  background-image: url("/src/graphics/about-bg.svg");
  background-repeat: no-repeat, repeat;
  background-size: 1425px;
  background-position-y: top;
  position: relative;
}

#about .about-page {
  padding: 200px;
  margin: auto;
}

#about .Typewriter {
  font-family: var(--game-font);
  color: var(--shade3);
  font-size: clamp(20px, 3vw, 45px);
  font-weight: 700;
  margin-bottom: 30px;
}

#about .about-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

#about .about-text {
  width: 70%;
  margin: 0 auto 50px;
}

#about .about-facts p{
  font-size: clamp(14px, 1.15vw, 18px);
  margin-left: 50px;
}

#about .photo-container {
  margin: 0 auto;
}

#about .about-photo {
  width: clamp(200px, 20vw, 280px);
  border-radius: 5px;
  top: 0px;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(250, 247, 247, 0.2), 0 6px 20px 0 rgba(115, 110, 110, 0.19);
  transition: 0.3s;
}

#about .about-photo:hover {
  top: -10px;
  box-shadow: 0 4px 8px 0 rgba(248, 247, 247, 0.2), 0 6px 25px 0 var(--red);
}

/* Experience */
#experience {
  background-image: url("/src/graphics/exp-bg.svg");
  background-repeat: no-repeat, repeat;
  background-size: 1425px;
  background-position-y: bottom;
}

#experience .experience-content {
  padding: 70px 200px;
  width: 100%;
  margin: auto;
}

#experience .Typewriter {
  font-family: var(--game-font);
  color: var(--shade3);
  font-size: clamp(20px, 3vw, 45px);
  font-weight: 700;
}

#experience .experience-container {
  position: relative;
  margin: 50px auto 0;
  background-color: rgba(0, 0, 0, 0.95);
  padding: 50px;
  width: 70%;
  color: white;
}

#experience .experience-container h3 {
  margin-bottom: 10px;
}

#experience .experience-container p {
  margin-top: 0;
  margin-bottom: 0;
}

#experience .location {
  font-size: clamp(12px, 1.2vw, 18px);
  color: var(--orange);
  font-weight: 500;
}

#experience a {
  position: absolute;
  font-size: clamp(20px, 3vw, 50px);
  transition: 0.3s;
  right: 20px;
  bottom: 20px;
}

#experience a:hover{
  font-size: clamp(30px, 4vw, 60px);
  bottom: 25px;
  right: 25px;
}


/* Projects */
#projects {
  background-image: url("/src/graphics/projects-bg.svg");
  background-repeat: no-repeat, repeat;
  background-size: 1100px;
  background-position-x: 200px;
  background-position-y: bottom;
}

#projects .projects-content {
  padding: 200px;
  margin: auto;
}

#projects .Typewriter {
  font-family: var(--game-font);
  color: var(--shade3);
  font-size: clamp(20px, 3vw, 45px);
  font-weight: 700;
}

#projects .boxes {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}

#projects .box-1 {
  background-image: url("/src/graphics/whitebox.svg");
}

#projects .box-2 {
  background-image: url("/src/graphics/greenbox.svg");
}

#projects .box-3 {
  background-image: url("/src/graphics/peachbox.svg");
}

#projects .box-4 {
  background-image: url("/src/graphics/bluebox.svg");
}

#projects .box-1, .box-2, .box-3, .box-4 {
  background-repeat: no-repeat, repeat;
  background-size: 100%;
  position: relative;
  width: 400px;
  height: 280px;
  padding: 20px;
  bottom: 0px;
  transition: 0.3s;
  margin: 20px;
}

#projects .box-1:hover, .box-2:hover, .box-3:hover, .box-4:hover {
  bottom: -10px;
}

#projects .box-text {
  width: 90%;
}

#projects .boxes a {
  transition: 0.3s;
}

#projects .boxes a:hover {
  color: var(--shade3)
}

#projects .chip {
  padding: 10px;
  background-color: rgba(241, 241, 241, 0.8);
  display: inline-block;
  border-radius: 10px;
  font-family: var(--code);
  font-size: 12px;
  margin: 5px;
  box-shadow: 0 4px 8px 0 rgba(250, 247, 247, 0.2), 0 6px 20px 0 rgba(148, 142, 142, 0.19);
  transition: 0.3s;
}

#projects .chip:hover {
  background-color: rgba(248, 246, 246, 0.95);
}


/* Contact */
#contact {
  background-image: url("/src/graphics/contact-bg.svg");
  background-repeat: no-repeat, repeat;
  background-size: 1425px;
  background-position: bottom;
}

#contact .contact-content {
  padding: 200px;
  margin: auto;
  text-align: center;
}

#contact .Typewriter {
  font-family: var(--game-font);
  color: var(--shade3);
  font-size: clamp(20px, 3vw, 45px);
  font-weight: 700;
}

#contact .contact-text {
  margin: 50px 0;
  max-width: 600px;
}

#contact .pow {
  position: relative;
  width: 50px;
  margin-top: 20px;
  top: 0px;
  transition: 0.3s;
}

#contact .pow:hover {
  top: -10px
}

/* Footer */
#footer {
  margin-left: 30px;
  position: fixed;
  bottom: -10px;
  align-content: center;
}

#footer .pipe {
  margin-top: 15px;
}

#footer .social-icons {
  display: flex;
  flex-direction: column;
  width: fit-content;
  font-size: clamp(14px, 1.6vw, 30px);
  color: var(--shade3);
  margin: 0 auto;
}

#footer .social-icons a {
  position: relative;
  top: 0px;
  left: -3px;
  transition: 0.3s;
  padding: 7px 0;
  animation: blink 3s infinite;
}

@keyframes blink {
  0%{color: var(--shade3); left: -3px}
  50%{color: var(--shade5); left: 3px}
  100%{color: var(--shade3); left: -3px}
}

#footer .social-icons a:hover {
  color: var(--green);
  top: -5px;
  animation: none;
}


/* Media Queries */
@media screen and (max-width: 768px) {
  #about .about-page {
    padding: 100px;
  }

  #about .about-text {
    width: 100%;
    margin: 0 auto 50px;
  }

  #experience .experience-content {
    padding: 70px 100px;
  }

  #projects .projects-content {
    padding: 100px;
  }

  #projects {
    background-position-x: 20px;
  }

  #contact .contact-content {
    padding: 100px;
  }

  #footer {
    position: relative;
    align-content: center;
  }

  #footer .pipe {
    display: none;
  }

  #footer .social-icons {
    display: block;
    width: fit-content;
    font-size: 30px;
    margin: 0 auto;
  }

  #contact .pow {
    display: none;
  }

  #footer .social-icons a {
    position: relative;
    top: 0px;
    left: -3px;
    transition: 0.3s;
    padding: 0 15px;
    animation: blink 3s infinite;
  }

  @keyframes blink {
    0%{color: var(--shade3); left: -3px}
    50%{color: var(--shade5); left: 3px}
    100%{color: var(--shade3); left: -3px}
  }

  #footer .social-icons a:hover {
    color: inherit;
    top: 0;
    animation: none;
  }
}

@media screen and (max-width: 450px) {
  #landing .typewriter {
    padding: 50px;
  }

  #landing .Typewriter {
    text-align: center;
    line-height: 30px;
  }

  #about .about-page {
    padding: 100px 50px;
  }

  #experience .experience-content {
    padding: 100px 30px;
  }

  #experience h3, #projects h3 {
    line-height: 20px;
    font-size: 12px;
  }

  #projects .projects-content {
    padding: 100px 0;
  }

  #projects .Typewriter {
    margin-left: 50px;
  }

  #projects {
    background-position-x: 50px;
  }

  #projects .box-1, .box-2, .box-3, .box-4 {
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: 100%;
    width: 250px;
    height: 330px;
  }

  #projects .box-1 {
    background-image: url("/src/graphics/whitebox2.svg");
  }

  #projects .box-2 {
    background-image: url("/src/graphics/greenbox2.svg");
  }

  #projects .box-3 {
    background-image: url("/src/graphics/peachbox2.svg");
  }

  #projects .box-4 {
    background-image: url("/src/graphics/bluebox2.svg");
  }

  #contact .contact-content {
    padding: 100px 50px;
  }

}